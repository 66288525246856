import { useState, useEffect } from 'react';
import { getSelectData } from '../../../utils/api/data';

const useOriginData = (username, initialFormData, onChange) => {
  const [localFormData, setLocalFormData] = useState(initialFormData);

  useEffect(() => {
    const getOriginData = async () => {
      try {
        const response = await getSelectData({
          selectValues:  'NAME, ADDRESS_1, CONTACT, PHONE, EMAIL, OPEN_TIME, CLOSE_TIME',
          tableName: 'forms.BIOVATION_ORIGIN_INFORMATION',
          whereField: 'USERNAME',
          whereValue: username,
        });
        
        if (response && response.length > 0) {
          const originData = response[0];
          const updatedFormData = {
            originName: originData.NAME,
            originAddress1: originData.ADDRESS_1,
            originContact: originData.CONTACT,
            originPhone: originData.PHONE,
            originEmail: originData.EMAIL,
            openTime: originData.OPEN_TIME,
            closeTime: originData.CLOSE_TIME,
          };
          setLocalFormData(prevState => ({ ...prevState, ...updatedFormData }));
          onChange({ ...initialFormData, ...updatedFormData });
        }
      } catch (error) {
        console.error("Error fetching origin data:", error);
      }
    };

    getOriginData();
  }, []);

  const handleChange = (e) => {
    let { id, value } = e.target;
    setLocalFormData(prevState => ({ ...prevState, [id]: value }));
    onChange({ ...localFormData, [id]: value });
  };

  return {
    localFormData,
    handleChange,
  };
};

export default useOriginData;
