import useOriginData from '../../../../hooks/forms/biovations/useOriginData';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import TimeSelect from '../../../../components/common/select/TimeSelect';

const StepTwo = ({ formData, onChange }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { localFormData, handleChange } = useOriginData(Cookies.get('cp-un'), formData, onChange);
  
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleKeyDown = (e) => {
    if (!(e.key === '.' || (e.key >= '0' && e.key <= '9') || e.key === 'Backspace' 
    || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' 
    || e.key === 'End' || e.key === 'Tab')) {
      e.preventDefault();
    }
  };

  return (
    <div className="BiovationDomStepTwo">
      <div className="BiovationFormContainer">
        <div className="BiovationFormFieldsStep2">
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="originName" label="Origin Name" 
                variant="outlined" value={localFormData.originName || ''} 
                onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="originAddress1" label="Origin Address 1" 
              variant="outlined" value={localFormData.originAddress1 || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originAddress2" label="Origin Address 2" 
              variant="outlined" value={localFormData.originAddress2 || ''} 
              onChange={handleChange}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originContact" label="Origin Contact" 
              variant="outlined" value={localFormData.originContact || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originPhone"label="Origin Phone" 
              variant="outlined" value={localFormData.originPhone || ''} 
              onChange={handleChange} required onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="originEmail" label="Origin Email" 
              variant="outlined" value={localFormData.originEmail || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TimeSelect id='openTime' selectLabel={'Open Time'} 
                handleChange={handleChange} value={localFormData.openTime}
                />
            </div>
            <div className="BiovationDomField">
              <TimeSelect id='closeTime' selectLabel={'Close Time'} 
                handleChange={handleChange} value={localFormData.closeTime}
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="pickupNumber" label="Pickup Number" 
                variant="outlined" value={formData.pickupNumber  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationFirstServe">
              <label>
                <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange}/>
                Is dock first come first serve?
              </label>
              <TextField
                size="small" id="dockFirstServe" label="Include all pick-up information here" 
                variant="outlined" value={formData.dockFirstServe  || ''} onChange={handleChange} 
                disabled={!isCheckboxChecked} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepTwo