import DownloadButton from '../../../components/common/button/DownloadButton';
import { getFilteredReport, getReport } from '../../../utils/api/reportData';
import DynamicTable from '../../../components/tables/dynamicTable';
import usePageView from '../../../hooks/analytics/usePageView.js';
import SelectBox from '../../../components/common/SelectBox';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './reweigh.css';
import Cookies from 'js-cookie';

const Reweigh = () => {
  usePageView();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [currentReportData, setCurrentReportData] = useState(null);


  const [filterByObject, setFilterByObject] = useState({
    'BOL #': true,
    'Pickup Month': true, 
    'Delivery Month': true, 
    'Origin Name': true, 
    'Original Weight Range': true, 
    'Billed Weight Range': true, 
    'Discrepancy (lbs) Range': true, 
    'Discrepancy (%) Range': true
  });
  const [currentFilterObject, setCurrentFilterObject] = useState({});
  

  const [searchBarPlaceHolder, setSearchBarPlaceHolder] = useState('Search Value');

  const [selectValue, setSelectValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const [loading, setLoading] = useState(true);

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [paginationEnabled, setPaginationEnabled] = useState(false);

  const maxRecordShow = 100;

  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };


  const handleSelectValue = (selectedValue) => { 
    setSelectValue(selectedValue);
    let placeholder 
    switch(selectedValue){
        case 'BOL #': placeholder = 'Ex. GTXXXXXX or GTXXXX01, GTXXXX02'; break;
        case 'Pickup Month':
        case 'Delivery Month':
            placeholder = 'Ex. YYYY-MM';
        break;
        case 'Origin Name': placeholder = 'Search Origin Name'; break;
        case 'Original Weight Range':
        case 'Billed Weight Range':
        case 'Discrepancy (lbs) Range':
        case 'Discrepancy (%) Range':
            placeholder = 'Begin, End (Ex. 0, 100)'
        break;
        default: placeholder = 'Search Value';
    }
    setSearchBarPlaceHolder(placeholder);
  };

  const fetchData = async() => {
    let tempData
    let data = await getReport('reweigh', Cookies.get('cp-cn'))
    
    if(data.length > maxRecordShow){
      tempData =  data.slice(maxRecordShow * (currentPageIndex - 1), maxRecordShow * currentPageIndex); 
      setPaginationEnabled(true);
    }
    else {
      tempData = data
      setPaginationEnabled(false);
    }
    setCurrentReportData(data);
    setReportData( {data: tempData, size: 'small'});
    setLoading(false);
  };

  useEffect(() => {
    if(Object.keys(currentFilterObject).length === 0) {
      const token = getAuthToken();
      token ? verifyToken(token) : navigate('/');
      fetchData();
      setSubmitEnabled(false);
    }
    else {
      setSubmitEnabled(true);
    }
  }, [currentFilterObject]);

  const handlePageChange = (index) => {
    setCurrentPageIndex(index);
    let tempData = currentReportData.slice(maxRecordShow * (index - 1), maxRecordShow * index)
    setReportData( {data: tempData, size: 'small'});
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleRemoveFilter = (event) => {
    const elementId = event.target.id;
    setCurrentFilterObject(prevState => {
        const temp = {...prevState};
        delete temp[elementId];
        return temp
    })
    setFilterByObject(prevState => {return {...prevState, [elementId]:true}})
  }

  const handleClearAll = () => {
    setCurrentFilterObject([])
    setFilterByObject(prevState => Object.fromEntries(Object.entries(prevState).map(([key, value]) => [key, true])));
  }

  const validateFilter = () => {
    let pass
    if (selectValue == null || searchValue == null){
      pass = false
    }
    else {
      switch(selectValue){
        case 'Pickup Month':
        case 'Delivery Month':
          pass = searchValue.includes('-')? true : false;
          if(pass){
            const splitDate = searchValue.split('-')
            pass = splitDate.length === 2? true : false;
            if(pass){
              const [year, month] = splitDate;
              pass = year.length === 4? true : false;
              if(pass){
                pass = month.length === 2? true : false;
              }
            }
          }
          break;
        case 'Original Weight Range':
        case 'Billed Weight Range':
        case 'Discrepancy (lbs) Range':
        case 'Discrepancy (%) Range':
          pass = searchValue.includes(',')? true : false;
          if(pass){
            const splitDate = searchValue.split(',')
            pass = splitDate.length === 2? true : false;
          }
          break;
        default: pass = true;
      }
    }

    return pass
  }

  const handleAddFilter = () => {
    let pass = validateFilter();
    if(!pass){
      window.alert('Invalid filter value format!\nFilter cannot be added!\nPlease follow the format shown in the input bar.')
    }
    else {
      setCurrentFilterObject(prevState => {return {...prevState, [selectValue]: searchValue}})
      setFilterByObject(prevState => {return {...prevState, [selectValue]: false};})
      setSearchValue('');
      setSubmitEnabled(true);
    }
  }
  

  const handleSubmit = async() => {
    let tempData
    const filteredata = await getFilteredReport('reweigh', Cookies.get('cp-cn'), currentFilterObject)
    setCurrentPageIndex(1);
    if(filteredata.length > maxRecordShow){
      tempData =  filteredata.slice(0, maxRecordShow * currentPageIndex); 
      setPaginationEnabled(true);
    }
    else {
      tempData = filteredata
      setPaginationEnabled(false);
    }
    setReportData( {data: tempData, size: 'small'});
    setCurrentReportData(filteredata);
    setSearchValue(null);
    setSubmitEnabled(false)
  }

  return (
    <div className="reweighPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="reweighPageContent">
          <div className="topReweigh">
            <h1>Reweigh Detail Report</h1>
            <p>* Analysis of the Previous 365 Days Based on Pickup Date</p>
            <p>* 100 Records Shown Per Page</p>
          </div>
          <div className="midReweigh">
            <div className="reweighReport">
              {loading ? (
                <p>Loading Report...</p>
              ) : (
                <DynamicTable dataObject={reportData} />
              )}
            </div>
            <div className='reweighPageIndex'>
              {paginationEnabled && (
                Array.from({ length: Math.ceil(currentReportData.length/maxRecordShow)}, (_, index) => (
                  <button
                    className='reweighPageIndexBtn'
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    disabled={currentPageIndex === index + 1}
                  >
                    {index + 1}
                  </button>
                ))
              )}
            </div>
            <div className='reweighCurrentFilterInfo'>
              <div className='reweighCurrentFilterList'>
                {Object.keys(currentFilterObject).length != 0 && (Object.entries(currentFilterObject).map(([key, value]) => (
                  <div className='reweighCurrentFilterItem'>{key}: {value} <span className='reweighRemoveFilter' id={key} onClick={handleRemoveFilter}>✖</span></div>
                  ))
                )}
              </div>
              {Object.keys(currentFilterObject).length != 0 && (
                <div className='reweighCurrentFilterButton'>
                  <div className="reweighClearAll">
                    <Button variant='contained' onClick={handleClearAll}>Clear All</Button>
                  </div>
                </div>
              )}
            </div> 
            <div className="reweighFilter">
              <div className="reweighCarrier">
                <SelectBox selectValue={handleSelectValue} selectObject={{label: 'Filter By', menuItems:Object.keys(filterByObject).filter(key => filterByObject[key] === true)
                }}/>
              </div>
              <div className="reweighSearch">
                <TextField 
                  label={searchBarPlaceHolder}
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
              <span className="reweighAddFilter" onClick={handleAddFilter}>+</span>
              <div className="reweighSubmit">
                {submitEnabled? (
                  <Button variant='contained' onClick={handleSubmit}>Submit Filters</Button>
                ) : (
                  <Button variant='text' className='disabledSumbit'>Submit Filters</Button>
                )}
              </div>
            </div>
          </div>
          <div className="bottomReweigh">
            <DownloadButton data={{data: currentReportData, size: 'small'}} fileName={'Reweigh-Report'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reweigh