import React, { useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const times = [
    "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM",
    "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM",
    "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM",
    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
    "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM",
    "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
  ];
  
function TimeSelect({id, selectLabel, handleChange, value }) {
  // Passing the ID explicitly 
  const handleSelectChange = (event) => {
    handleChange({ target: { id, value: event.target.value } });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="time-select-label">{selectLabel}</InputLabel>
      <Select
        size='small'
        labelId="time-select-label"
        id={id}
        value={value}
        onChange={handleSelectChange}
        label="Choose a time"
      >
        {times.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TimeSelect;
