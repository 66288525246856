import getChartType from '../../utils/helpers/getChartType';
import React, { useEffect, useState } from 'react';
import { config } from '../../config/Constants';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Axios from 'axios';

const ChartController = ({ chartId }) => {
  const [height, setHeight] = useState('400px');
  const [chart, setChart] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);

  const newchartData = useSelector((state) => state.chartfilter.chartData);
  const getCompany = () => { return Cookies.get('cp-cn'); };

  useEffect(() => { getData(); }, []);

  useEffect(() => {
    if (Object.keys(newchartData).length !== 0) {
      updateChart(newchartData);
    }
  }, [newchartData]);

  const updateChart = async(newChartData) => {
    const response = await Axios.get(config.url.GET_CHART_INFO, {
      params: { chartId: newChartData.chartId, }
    });
    if (newChartData.chartId === chartId) {
        const chartData = {'data': newChartData.data.chartObject, ...response.data.chartObject}
        const chart = await getChartType(chartData, setHeight);
        setChart(chart);
    }
  };

  const getData = async() => {
    const companyName= getCompany()
    const response = await Axios.get(config.url.GET_DASHBOARD_CHART_DATA, {
      params: {
        chartId: chartId,
        companyName: companyName,
      }
    });
    const chart = await getChartType(response.data.chartObject, setHeight);
    setChart(chart);
    setTitle(response.data.chartObject.chartInfo[0].COMPONENT_NAME);
    setSubTitle(response.data.chartObject.chartInfo[0].SUB_COMPONENT_NAME);
  };

  return (
    <div className="chart-container" style={{ height: height, width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
      <div style={{ textAlign: 'center', height: '25px', paddingBottom: '20px', flexShrink: 0 }}>
        <h3>{title}</h3>
      </div>
      {subTitle && (<div style={{ textAlign: 'center', height: '25px', paddingBottom: '20px', flexShrink: 0, fontStyle: 'italic' }}><p>{subTitle}</p></div>)}
      <div style={{ flexGrow: 1, minHeight: 0, display: 'flex', alignItems: 'center'}}>
        <div style={{ width: '100%', height: '100%' }}>
          {chart}
        </div>
      </div>
    </div>
  );
};

export default ChartController;