import ChargeDescription from '../../pages/report/chargeDescription/ChargeDescription';
import TnCostBreakdown from '../../pages/report/tnCostBreakdown/TnCostBreakdown';
import LaneviewTrends from '../../pages/report/laneviewTrends/LaneviewTrends';
import ServiceStats from '../../pages/report/serviceStats/ServiceStats';
import FormTracker from '../../pages/report/form_tracker/FormTracker';
import Reweigh from '../../pages/report/reweigh/reweigh';
import { useParams } from 'react-router-dom';
import React from 'react';

const ReportRenderer = () => {
    const { url } = useParams();

    let reportComponent;
    switch (url) {
      case 'trackingcostbreakdown':
        reportComponent = <TnCostBreakdown />;
        break;
      case 'servicestats':
        reportComponent = <ServiceStats />;
        break;
      case 'chargedescription':
        reportComponent = <ChargeDescription />;
        break;
      case 'reweigh':
        reportComponent = <Reweigh />;
        break;
      case 'laneviewtrend':
        reportComponent = <LaneviewTrends />;
        break;
      case 'formtracker':
        reportComponent = <FormTracker />;
        break;
      default:
        reportComponent = <div>Report not found</div>;
    }

  return (
    <div>
      {reportComponent}
    </div>
  );
}

export default ReportRenderer