import FileExplorer from '../../../components/fileExplorer/FileExplorer';
import SearchBar from '../../../components/common/SearchBar';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import verifyAdmin from '../../../utils/adminUtils';
import React, { useEffect, useState } from 'react';
import { config } from '../../../config/Constants';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './loadquote.css';

const Loadquote = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const searchConfig = { size: 'normal', keyPress: true }
  const [blobObject, setBlobObject] = useState({
    fileData: {},
    blobContainer: 'cp-form-loadquote',
    fileExplorerAttribute :{
      fileHeaders: ['File Name', 'BOL Number', 'Created Date', 'Action'],
      fileButtons: ['Download', 'Cost Description']
    },
    totalCount: 0,
  });

  const getAuthToken = () => {
    return Cookies.get('gtms-cp-auth');
  };

  const inputValue = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    verifyAdmin(navigate);
  }, []);

  useEffect(() => {
    const getFiles = async() => {
      const totalCount= await getTotalCount();
      const fileData = await getFilesInfo();
      setBlobObject(prevState => ({
        ...prevState,
        fileData: fileData,
        totalCount: totalCount,
      }));
    }
    getFiles();
  }, []);

  const onSearch = async(value) => {
    setSearchValue(value);
    const fileData = await handleLoadQuoteSearch();
    setBlobObject(prevState => ({
      ...prevState,
      fileData: fileData,
    }));
  }

  const getFilesInfo = async() => {
    let fileInfo;
    let response = await Axios.get(config.url.GET_PAGINATION_SELECT_ALL, {
      params: {
        selectValues: ['BLOB_FILE_NAMES AS FILE_NAME', 'BOL_NUMBER', 'INGESTED_TIMESTAMP'],
        tableName: '[forms].[LOAD_QUOTE_FORM_SUBMISSIONS]',
        orderBy: ['INGESTED_TIMESTAMP'],
        offset: offset,
        fetch: 100
      }
    });
    fileInfo = {fileObject: response.data.fileObject};
    return fileInfo
  }

  const handleLoadQuoteSearch = async() => {
    let fileInfo;
    const response = await Axios.get(config.url.GET_SELECT_DATA, {
      params: {
        selectValues: ['BLOB_FILE_NAMES AS FILE_NAME', 'BOL_NUMBER', 'INGESTED_TIMESTAMP'],
        tableName: '[forms].[LOAD_QUOTE_FORM_SUBMISSIONS]',
        whereField: 'BOL_NUMBER',
        whereValue: searchValue,
      }
    });
    fileInfo = {fileObject: response.data.dataResults};
    return fileInfo
  }

  const getTotalCount = async () => {
    const response = await Axios.get(config.url.GET_COUNT_RECORDS, {
      params: {
        tableName: '[forms].[LOAD_QUOTE_FORM_SUBMISSIONS]',
      }
    });
    return response.data.totalCount[0].TOTAL_COUNT
  }

  /*
  const handleLoadQuoteSearch = async() => {
    let filesInfo;
    const response = await Axios.get(config.url.SEARCH_BLOB_CONTAINER, {
      params: {
        blobContainer: blobObject.blobContainer,
        searchValue: searchValue,
      }
    });
    filesInfo = {fileObject: response.data.fileObject}
    return filesInfo
  }

  const getBlobFiles = async() => {
    const response = await Axios.get(config.url.GET_BLOB_FILES, {
      params: {
        blobContainer: blobObject.blobContainer,
        continuationToken: '',
        rowsPerPage: 100
      }
    });
    return response.data.fileObject;
  };
  */

  return (
  <div className="loadQuoteAdmin">
    <div className="LandingPageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="loadQuoteAdminContent">
        <div className="loadQuoteAdminTop">
          <div className="loadQuoteTitle">
            <h1>Load Quote Admin</h1>
          </div>
          <div className="loadQuoteSearch">
            <div className="loadQuoteSearchBar">
            <SearchBar inputValue={inputValue} onSearch={onSearch} config={searchConfig}/>
            </div>
            <div className="loadQuoteButton">
              <Button
                variant='contained' 
                onClick={() => onSearch()}>
                  Search BOL
              </Button>
            </div>
          </div>
        </div>
        <div>
          <hr className='loadQuoteHR'/>
        </div>
        <div className="loadQuoteAdminMid">
          <div className="loadQuoteFileExplorer">
            <FileExplorer fileObject={blobObject} />
          </div>
        </div>
        <div className="loadQuoteAdminBottom">
        </div>
      </div>
    </div>
  </div>
  )
}

export default Loadquote