
import { Table, TableBody, TableCell, TableHead, TableRow, Button, styled, TablePagination } from '@mui/material';
import { Description as DescriptionIcon } from '@mui/icons-material';
import { config } from '../../config/Constants';
import FileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import showDataPopup from '../../utils/components/sweetAlertUtils';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize + 2,
  cursor: 'pointer',
}));

const FileExplorer = ({ fileObject}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [files, setFiles] = useState({});
  const [length, setLength] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [fileAttribute, setFileAttribute] = useState({});

  useEffect(() => {
      setFiles(fileObject.fileData.fileObject);
      setLength(fileObject.totalCount);
      setFileAttribute(fileObject.fileExplorerAttribute)
  }, [fileObject]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value); 
  };

  const getFile = async (fileName, container) => {
    const response = await Axios.get(config.url.GET_BLOB_FILE, {
      params: {
        fileName: fileName,
        blobContainer: container,
      },
      responseType: 'arraybuffer',
    });
    FileDownload(response.data, fileName);
  }; 

  const getCostDescription = async (fileName) => {
    const response = await Axios.get(config.url.GET_SELECT_DATA, {
      params: {
        selectValues: ['DESCRIPTION', 'COST'],
        tableName: '[forms].[LOAD_QUOTE_FORM_SUBMISSIONS]',
        whereField: 'BLOB_FILE_NAMES',
        whereValue: fileName,
      },
    });
    return response.data.dataResults;
  }

  const handleButtonClick = async(button, file) => {
    if(button === 'Remit Download') {
      getFile(file, 'remit-archive')
    }
    else if (button === 'Cost Description') {
      const data = await getCostDescription(file);
      showDataPopup('Cost Description Fields', data)
    }
  }

  return (
    <div style={{ maxHeight: '95%', overflowY: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            {fileAttribute && fileAttribute.fileHeaders && 
              fileAttribute.fileHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>{header}</StyledHeaderCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {files && Object.keys(files).length > 0 && 
            Object.keys(files).map(file => (
              <TableRow key={file}>
                {Object.keys(files[file]).map(property => (
                  <TableCell key={property}>
                    {files[file][property]}
                  </TableCell>
                ))}
                <TableCell>
                  {fileAttribute && fileAttribute.fileButtons &&
                    fileAttribute.fileButtons.map((button, index) => (
                      <Button key={index}
                        variant='outlined'
                        onClick={() => handleButtonClick(button, files[file].FILE_NAME)}
                        style={{ marginRight: '10px' }}
                        >
                      {button}
                      </Button>
                    ))}
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default FileExplorer;
