import { getSelectData } from '../../utils/api/data';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useFetchAccountNumbers = () => {
  const [accountNumberItems, setAccountNumberItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await getSelectData({
        selectValues: '"AccountNumber"',
        tableName: 'REPORT.ADDRESS_CORRECTION_DETAILS',
        whereField: 'Customer', whereValue: Cookies.get('cp-cn'), db: 'SF'
      });
      const accountNumbers = items.map((item) => item.AccountNumber);
      setAccountNumberItems(accountNumbers);
    };

    fetchData();
  }, []);

  return accountNumberItems;
};

export default useFetchAccountNumbers;
