import ReportFilterPanel from '../common/filterPanel/report/FilterPanel';
import ChartFilterPanel from '../common/filterPanel/chart/FilterPanel';
import { excelDownload } from '../../utils/components/downloadUtils';
import { csvDownload } from '../../utils/components/downloadUtils';
import { getDownload } from '../../utils/helpers/getDownloadData';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getSelectData } from '../../utils/api/data';
import React, { useEffect, useState } from 'react';
import { config } from '../../config/Constants';
import { useNavigate } from 'react-router-dom';
import { Modal, Box } from '@mui/material';
import Button from '@mui/material/Button';
import './css/buttonController.css';
import Cookies from 'js-cookie';
import Axios from 'axios';

const ButtonController = ({ chartId, reportId }) => {
  const navigate = useNavigate();
  const [downloadButton, setDownloadButton] = useState(false);
  const [viewReportButton, setViewReportButton] = useState(false);
  const [filterPanel, setFilterPanel] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [excelButton, setExcelButton] = useState(false);

  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const openFilterModal = () => setFilterModalOpen(true);
  const closeFilterModal = () => setFilterModalOpen(false);

  useEffect(() => { 
    const fetchData = chartId ? getChartData : getReportData;
    fetchData();
  }, []);

  const getChartData = async() => {
    const response = await Axios.get(config.url.GET_DASHBOARD_BUTTONS, {
      params: { chartId: chartId }
    });
    await getButtons(response.data.button);
  };

  const getReportData = async() => {
    const response = await getSelectData({
      selectValues: 'VIEW_REPORT_BUTTON, DOWNLOAD_BUTTON, FILTER_PANEL, EXCEL_DOWNLOAD_BUTTON',
      tableName: 'reports.REPORT_BUTTONS',
      whereField: 'FK_REPORT_ID',
      whereValue: reportId,
    });
    await getButtons(response[0])
  }

  const getButtons = async(buttons) => {
    const isDownloadButton = buttons.DOWNLOAD_BUTTON;
    const isViewReportButton = buttons.VIEW_REPORT_BUTTON;
    const isFilterPanel = buttons.FILTER_PANEL;
    const isExcelDownloadButton = buttons.EXCEL_DOWNLOAD_BUTTON;

    if (isDownloadButton) { setDownloadButton(true); }
    if (isViewReportButton) { setViewReportButton(true); }
    if (isFilterPanel) { setFilterPanel(true); }
    if (isExcelDownloadButton) { setExcelButton(true); }
  }

  const downloadReport = async() => {
    const customer = Cookies.get('cp-cn');
    const response = await Axios.get(config.url.GET_DOWNLOAD_REPORT, {
      params: { chartId: chartId, customer: customer }
    });
      csvDownload(response)
  }

  const excelDownloadReport = async() => {
    const data = await getDownload(chartId);
    excelDownload(data[0], data[1]);
  }

  const viewReport = () => {
    if (chartId === 1) {
      navigate('/auditcenter');
    }
    else if (chartId === 14) {
      navigate('/reports/reweigh');
    }
  }

  const viewPanel = () => { setFilterToggle((prevOpen) => !prevOpen); }

  return (
    <div className='button-container'>
      {downloadButton && (
        <div className='button-wrapper'>
          <Button onClick={downloadReport}>
            <FileDownloadIcon/>
          </Button>
        </div>
      )}
      {excelButton && (
        <div className='button-wrapper'>
          <Button onClick={excelDownloadReport}>
            <FileDownloadIcon/>
          </Button>
        </div>
      )}
      {viewReportButton && (
        <div className='button-wrapper'>
          <Button onClick={viewReport}>
            View Detailed Report
          </Button>
        </div>
      )}
      {filterPanel && chartId && (
        <div className='button-wrapper'>
          {filterToggle && <ChartFilterPanel chartId={chartId}/>}
          <Button onClick={viewPanel}>
            <FilterAltIcon />
          </Button>
        </div>
      )}
      {filterPanel && reportId &&(
        <div className='button-wrapper'>
          <Modal open={isFilterModalOpen} onClose={closeFilterModal}>
            <Box className="modal-content">
              <ReportFilterPanel reportId={reportId} closeModal={closeFilterModal}/>
            </Box>
          </Modal>
          <Button onClick={openFilterModal}>
            <FilterAltIcon />
          </Button>
        </div>
      )}
    </div>
  )
}

export default ButtonController