import React from 'react';
import './summarydata.css'

const SummaryData = ({chartData}) => {
  return (
    <div>
      {chartData && chartData.map((item, index) => (
        <div className='box' key={`${index}`}>
          <div className="summaryBox">
            <div className="pieChartSubTitle">
              <h4>
                {item.LABEL.toLowerCase()
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, char => char.toUpperCase())}
              </h4>
            </div>
            <div className="pieChartValue">
              {item.VALUE}
            </div>
          </div>
        </div>
      )
      )}
    </div>
  )
}

export default SummaryData