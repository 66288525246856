import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';

const SelectBox = ({selectValue, selectObject, initialValue }) => {
  const [storeValue, setStoreValue] = useState('');
  const label = selectObject && selectObject.label ? selectObject.label : '';

  useEffect(() => {
    if (initialValue) {
      setStoreValue(initialValue);
    }
  }, [initialValue]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setStoreValue(value);
    selectValue(value);
  }

  return (
    <div>
      <FormControl fullWidth size={selectObject.smallSize && "small"}> 
        <InputLabel id="select-label">{label}</InputLabel>
        <Select 
          labelId="SelectBoxLabel" 
          id="selectBox"
          label={label} 
          onChange={handleSelectChange}
          value={storeValue}
        >
          {selectObject.menuItems.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
        ))}
        </Select>
      </FormControl>
    </div>
  )
};

export default SelectBox