const config = {
    url: {
        ACCOUNT_INSERT_RECORD: 'http://localhost:3001/account/insertrecord',
        ACCOUNT_UPDATE_FIELD: 'http://localhost:3001/account/updaterecord',
        FORGOT_PASSWORD: 'http://localhost:3001/password/forgotpassword',
        GET_AUDIT_CHART_DATA: 'http://localhost:3001/chart/getauditchartdata',
        GET_AUDIT_SERVICE_DESC: 'http://localhost:3001/chart/getauditsd',
        GET_BLOB_FILE: 'http://localhost:3001/blob/getblob',
        GET_BLOB_FILES: 'http://localhost:3001/blob/getblobfiles',
        GET_CHART_INFO: 'http://localhost:3001/chart/getchartinfo',
        GET_COUNT_RECORDS: 'http://localhost:3001/data/getcountrecords',
        GET_COMPANIES: 'http://localhost:3001/account/getcompanies',
        GET_CUSTOM_QUERIES: 'http://localhost:3001/data/getcustomquery',
        GET_DASHBOARD_BUTTONS: 'http://localhost:3001/chart/getdashboardbutton',
        GET_DASHBOARD_CHART_DATA: 'http://localhost:3001/chart/getdashboardchart',
        GET_DOWNLOAD_REPORT: 'http://localhost:3001/file/downloadreport',
        GET_DOWNLOAD_AUDIT: 'http://localhost:3001/chart/getauditdownload',
        GET_FILTERED_REPORT: 'http://localhost:3001/reports/getfilteredreport',
        GET_FILTER_AUDIT_CHART_DATA: 'http://localhost:3001/chart/getfilteredauditchartdata',
        GET_FORM_ACCESSORIALS: 'http://localhost:3001/forms/getformaccessorials',
        GET_FORMS: 'http://localhost:3001/forms/getforms',
        GET_IP_ADDRESS: 'http://localhost:3001/auth/getip',
        GET_LANDING_PAGE: 'http://localhost:3001/auth/getlandingpage',
        GET_LANEVIEW_SHIPMENT: 'http://localhost:3001/laneview/getshipment',
        GET_LANEVIEW_TOGGLE_DATA: 'http://localhost:3001/laneview/toggle',
        GET_PAGE_ACCESS: 'http://localhost:3001/account/getpageaccess',
        GET_PAGINATION_SELECT_ALL: 'http://localhost:3001/data/getpaginationselect',
        GET_REMIT_FILES: 'http://localhost:3001/remits/getremits',
        GET_REMIT_SEARCH: 'http://localhost:3001/remits/searchremits',
        GET_REPORT: 'http://localhost:3001/reports/getreport',
        GET_REPORTS: 'http://localhost:3001/reports/getreports',
        GET_ROLE_LIST: 'http://localhost:3001/account/getrolelist', 
        GET_TRACKING_REPORTS: 'http://localhost:3001/tracking/gettrackingreports',
        GET_TRACKING_DATA: 'http://localhost:3001/tracking/gettrackingdetails',
        GET_SAAS_DOMESTIC_COUNTRY: 'http://localhost:3001/laneview/getdomesticcountry',
        GET_SAAS_CITIES: 'http://localhost:3001/laneview/getcities',
        GET_SAAS_POSTAL: 'http://localhost:3001/laneview/getpostals',
        GET_SAAS_STATE: 'http://localhost:3001/laneview/getstates',
        GET_SELECT_DATA: 'http://localhost:3001/data/getselectdata',
        GET_USER_ACCOUNTS: 'http://localhost:3001/account/getaccounts',
        GET_USER_CREDS: 'http://localhost:3001/auth/usercreds',
        GET_WIDGET_DATA: 'http://localhost:3001/widget/getvalue',
        GET_WIDGET_DETAILS: 'http://localhost:3001/widget/getdetails',
        HOME_URL: 'http://localhost:3001/',
        LOGIN_URL: 'http://localhost:3001/auth',
        OCEAN_TRACKING_DETAILS: 'http://localhost:3001/tracking/oceantrackingdetails',
        OCEAN_TRACKING_FILTER: 'http://localhost:3001/tracking/oceantrackingfilter',
        OCEAN_TRACKING_SUBMIT: 'http://localhost:3001/tracking/oceantrackingcoordinates',
        RESET_FORM: 'http://localhost:3001/forms/resetform',
        RESET_PASSWORD: 'http://localhost:3001/password/resetpassword',
        SEARCH_BLOB_CONTAINER: 'http://localhost:3001/blob/searchBlob',
        SEARCH_PANEL_FILTER: 'http://localhost:3001/chart/searchfilterpanel',
        SUBMIT_BIOVATIONS_DOMESTIC_FORM: 'http://localhost:3001/forms/submitbiovationsdomestic',
        SUBMIT_CSV_FORM: 'http://localhost:3001/forms/submitcsvform',
        SUBMIT_FORM : 'http://localhost:3001/forms/submitform',
        SUBMIT_LOAD_QUOTE: 'http://localhost:3001/forms/submitloadquote',
        SUBMIT_ENHANCEMENT: 'http://localhost:3001/forms/submitenhancement',
        TEMP_FILE_UPLOAD: 'http://localhost:3001/file/uploadtempfile',
        USER_REGISTER: 'http://localhost:3001/auth/register',
        VERIFY_TOKEN: 'http://localhost:3001/auth/verifytoken',
        VERIFY_ADMIN: 'http://localhost:3001/auth/verifyadmin',
    }
};

   export default config;