import { popUpFormMessage, showSubmittingNotification, closeSubmittingNotification } from '../../../../utils/components/popup';
import { usePoDetailsHandlers } from '../../../../hooks/forms/general/usePoDetailHandlers';
import useUpdateFormData from '../../../../hooks/forms/general/useUpdateFormData';
import useStepRenderer from '../../../../hooks/forms/gtmsinbound/useStepRenderer';
import { useStepHandlers } from '../../../../hooks/forms/general/useStepHandler';
import MyStepper from '../../../../components/common/stepper/CustomStepper';
import useGtmsLogo from '../../../../hooks/forms/gtmsinbound/useGtmsLogo';
import useFields from '../../../../hooks/forms/general/useFields';
import useFormId from '../../../../hooks/forms/general/useFormId';
import usePageView from '../../../../hooks/analytics/usePageView';
import Sidebar from '../../../../components/sidebar/Sidebar';
import { getSelectData } from '../../../../utils/api/data';
import NavBar from '../../../../components/navbar/NavBar';
import verifyToken from '../../../../utils/tokenUtils';
import { config } from '../../../../config/Constants';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import './gtmsinbound.css';
import Axios from 'axios';

const STEPS = [ 'Requesting Company', 'Origin Information', 'Origin Location'/*, 'Accessorial'*/, 'PO Details'];

const GtmsInbound = () => {
  usePageView();
  const navigate = useNavigate();
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  useEffect(() => {
    const token = getAuthToken();
    if (Cookies.get('cp-cn') !== 'GTMS-CP') {
      navigate('/')
    }
    token ? verifyToken(token) : navigate('/');
  }, []);

  // Templates
  const poDetailsTemplate = {
    palletNumber: "", pieces: "", sku: "", description: "", po: "", length: "", width: "", 
    height: "", weight: "", nmfc: ""
  };

  // Hooks and States
  const { customerLogo } = useGtmsLogo();
  const [activeStep, setActiveStep] = useState(0);
  const [poCount, setPoCount] = useState([{COUNT: 0}]);
  const [poDetails, setPoDetails] = useState(null)
  const [formData, setFormData] = useState({
    formId: "",  requestingCompany: {},  originInformation: {}, originLocation: {}, 
    poDetails: [ { ...poDetailsTemplate } ], poList: [],
  });

  // Handlers
  const formId = useFormId('k2j');
  const { addNewEntry, handleChangeDetails } = usePoDetailsHandlers( formData, setFormData, poDetailsTemplate,);
  const { handleChange } = useStepHandlers(formData, setFormData);

  // Get Po List 
  const pos = useFields({
    fields: 'PO_NUMBER',
    table: 'BookingRequest.dbo.UNDERBOOKED_PO_STATUS',
    where: 'VENDOR',
    value: Cookies.get('cp-vr')
  });

  // Auto Complete Cache
  const requestingCompany = useFields({
    fields: 'COMPANY AS companyContact, CONTACT_NAME as companyName, CONTACT_EMAIL as companyEmail',
    table: 'forms.K2J_VENDORS', 
    where: 'COMPANY', 
    value: Cookies.get('cp-vr')
  });

  const originInformation = useFields({
    fields: `COMPANY AS originName, ADDRESS_1 AS originAddress1, ADDRESS_2 AS originAddress2,
     CONTACT_NAME as originContact, CONTACT_PHONE as originPhone, CONTACT_EMAIL as originEmail, 
     DOCK_HOURS_START as originOpenTime, DOCK_HOURS_END as originCloseTime`,
    table: 'forms.K2J_VENDORS', 
    where: 'COMPANY', 
    value: Cookies.get('cp-vr')
  });

  const originLocation = useFields({
    fields: 'TRIM(POSTAL) AS originPostal, TRIM(CITY) AS originCity, TRIM(STATE) as originState, TRIM(COUNTRY) as originCountry',
    table: 'forms.K2J_VENDORS', 
    where: 'COMPANY',
    value: Cookies.get('cp-vr')
  });

  // TODO: Consider Hook?
  // Check if value has been for edit submission
  useEffect(() => {
    const getfields = async() => {
      const data = await getSelectData({
        selectValues: 'COUNT(PO_NUMBER) AS COUNT', 
        tableName: 'BookingRequest.dbo.K2J_EDIT_SUBMISSIONS',
        whereField: 'PO_NUMBER', 
        whereValue: formData.requestingCompany.po
      });
      setPoCount(data);
    }
    getfields();
}, [formData.requestingCompany.po]);

const getPoDetails = async() => {
  if (poCount[0].COUNT > 0) {
    const response = await Axios.get(config.url.GET_CUSTOM_QUERIES, {
      params: {
        type: 'k2j-details',
        args: {po: formData.requestingCompany.po}
      }
    });
    setPoDetails(response.data.dataResults);
  }
}

useEffect(() => {
  getPoDetails()
  }, [poCount]);

useEffect(() => { 
  if (poDetails !== null) {
    setFormData(prevFormData => ({
      ...prevFormData,
      poDetails: [...poDetails]
    }));
  }
}, [poDetails]);

  // Obtaining Auto Complete Data
  useUpdateFormData(setFormData, 'formId', formId);
  useUpdateFormData(setFormData, 'requestingCompany', requestingCompany ? requestingCompany[0] : null);
  useUpdateFormData(setFormData, 'originInformation', originInformation ? originInformation[0] : null);
  useUpdateFormData(setFormData, 'originLocation', originLocation ? originLocation[0] : null);
  useUpdateFormData(setFormData, 'poList', pos);

  // Step Rendering
  const renderStep = useStepRenderer();

  // Navigation
  const handleNext = () => {
    const validated = validate();
    if (validated == true) {
      if (activeStep === STEPS.length - 1) {
        handleSubmit();
      } else if (poCount[0].COUNT > 0) { // PO Number previously submitted: Skip Steps 1,2
        setActiveStep(3)
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
        alert("Please fill out all required fields before proceeding.");
      }
  }

  const validateFields = () => {
    switch(activeStep) {
      case 0:
        return {'requestingCompany': ['companyName', 'companyContact', 'companyEmail', 'po']};
      case 1:
        return {'originInformation': ['originName', 'originAddress1', 'originContact', 'originPhone', 'originEmail', 'originOpenTime', 'originCloseTime', 'originPickupNumber']};
      case 2:
        return {'originLocation': ['originCountry', 'originState', 'originCity', 'originPostal']};
      case 3:
        return {'poDetails': ['palletNumber', 'pieces', 'sku', 'description', 'po', 'length', 'width', 'height', 'weight']}
    }
  }

  const validate = () => {
    let keysToCheck = validateFields();
    const currentStepKey = Object.keys(keysToCheck)[0];
    const currentStepFields = keysToCheck[currentStepKey] || [];

    if (currentStepKey === 'poDetails' && Array.isArray(formData[currentStepKey])) {
      return formData[currentStepKey].every(detail => {
        return currentStepFields.every(key => {
          const value = detail[key];
          return value !== '' && value !== null && value !== undefined;
        });
      });
    } else {
        const allKeysExist = currentStepFields.every(key => {
        const value = formData[currentStepKey]?.[key];
        return value !== '' && value !== null && value !== undefined;
      });
      return allKeysExist;
    }
  }

  const handleEditFinish = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'K2J Edit Submission', value: 1 });
    showSubmittingNotification();
    const response = await Axios.post(
      config.url.SUBMIT_FORM, {
        formData: formData,
        formType: 'K2J-Domestic-edit',
        userName: Cookies.get('cp-un')
      }
    );
    closeSubmittingNotification();
    popUpFormMessage(response.data.success);
  }

  const handleSubmit = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'K2J Final Submission', value: 1 });
    showSubmittingNotification();
    const response = await Axios.post(
      config.url.SUBMIT_FORM, {
        formData: formData,
        formType: 'K2J-Domestic',
        userName: Cookies.get('cp-un')
      }
    );
    closeSubmittingNotification();
    popUpFormMessage(response.data.success);
  }

  const reset = async() => {
    const response = await Axios.post(
      config.url.RESET_FORM, {
        userName: Cookies.get('cp-un'),
        form: 'INBOUND'
      }
    );
    window.location.reload();
  }

  return (
    <div className='LandingPage'>
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="gtmsInboundDomesticPageContent">
          <div className="gtmsInboundDomesticForm">
            <div className="gtmsInboundDomesticFormTop">
              <div className="gtmsInboundDomesticTitle">
                <h1 style={{textAlign: 'center'}}>GTMS Inbound Demo</h1>
                <h3 style={{textAlign: 'center'}}>Form ID: {formId}</h3>
              </div>
              <div className="gtmsInboundDomesticLogo">
                {customerLogo && <img src={customerLogo} alt="Company Logo" style={{height: '50px'}}/>}
              </div>
            </div>
            <div className="gtmsInboundDomesticFormMid">
              <div className="gtmsInboundDomesticStepper">
                <MyStepper steps={STEPS} activeStep={activeStep}/>
              </div>
              <div className="gtmsInboundDomesticFormSubtitle">
                <h3>{STEPS[activeStep]} fields</h3>
              </div>
              <div className="gtmsInboundDomesticFormContent">
                {renderStep(activeStep, formData, handleChange, handleChangeDetails, addNewEntry)}
              </div>
              <div className="gtmsInboundDomesticSubmit">
                <div className="editFinish">
                  {activeStep === STEPS.length - 1 && (
                    <Button            
                      variant="contained" 
                      sx={{ backgroundColor: '#FFFDD0', color: '#000000' }} 
                      onClick={handleEditFinish}>
                        Save Draft Submission
                    </Button>
                  )}
                </div>
                <div className="finalFinish">
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleNext}>
                      {activeStep === STEPS.length - 1 ? 'Final Submit' : 'Next'}
                  </Button>
                </div>
                <div className="clearDemoPos">
                  {activeStep === 0 && (
                    <Button
                      variant="outlined" 
                      color="primary" 
                      onClick={reset}>
                      Clear PO's
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="gtmsInboundDomesticFormBottom"></div>
          </div>
        </div>       
      </div>
    </div>
  )
}

export default GtmsInbound
