import { useState, useEffect } from 'react';
import { getSelectData } from '../../../utils/api/data';

const useFetchOriginGeo = () => {
    const [geoLocation, setGeoLocation] = useState(null)
    useEffect(() => {
        const getLocationData = async() => {
            const data = await getSelectData({
                selectValues:  'ZIP, STATE, CITY, COUNTRY',
                tableName: 'forms.BIOVATIONS_VENDORS_DESTINATION_LOCATION',
                whereField: 'ID',
                whereValue: '1',
              });
              setGeoLocation(data)
        }
    getLocationData();
    }, [])

    return geoLocation
}

export default useFetchOriginGeo;