import { setReportFilter } from '../../../../redux/slices/reportfilterSlice';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import useOperatorField from '../../../../hooks/filter/useOperatorField';
import useSelectData from '../../../../hooks/data/useSelectData';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress  } from '@mui/material';
import Button from '@mui/material/Button';
import SelectBox from '../../SelectBox';
import './filterPanel.css';

const FilterPanel = ({reportId, closeModal}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fieldSelect, setFieldSelect] = useState([]);
  const [rows, setRows] = useState([{id: Date.now(), field: '', operator: '', value: ''}]);

  // Hook: Get select field values
  const fieldNames = useSelectData({
    selectValues: 'ID, FIELD_NAME', tableName: 'reports.FILTER_FIELDS',
    whereField: 'REPORT_ID', whereValue: reportId,
  });

  // Setting the useState of fieldSelect
  useEffect(() => {
    if (fieldNames.length > 0) {
      const data = fieldNames.map(item => item.FIELD_NAME);
      setFieldSelect(data);
    }
  }, [fieldNames]);

  // Hook to obtain Operator Field Values
  const { operatorSelect, handleOperatorFieldChange } = useOperatorField();
  const reportFilters = useSelector((state) => state.report.reportFilter);

  // Check reportFilters and update the filter rows
  useEffect(() => {
    if (reportFilters.every(row => row.field && row.operator && row.value)) {
      setRows(reportFilters);

      reportFilters.forEach(row => {
        handleOperatorFieldChange(row.id, row.field, fieldNames);
      });
    }
  }, [reportFilters, fieldNames]);

  // Handle Changes in SelectBox and TextField
  const handleValue = (rowId, type, value) => {
    updateRow(rowId, type, value);
    if (type === 'field') handleOperatorFieldChange(rowId, value, fieldNames);
  };

  // Update rows useState object
  const updateRow = (rowId, type, value) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId ? { ...row, [type]: value } : row
      )
    );
  };

  // Add a new filter row
  const addRow = () => {
    setRows([...rows, {id: Date.now(), field: '', operator: '', value: '' }]);
  }

  // Remove filter row
  const removeRow = (id) => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  const handleSubmit = async() => {
    dispatch(setReportFilter(rows));
    closeModal();
  }

  return (
    <div className='report-filter'>
      <div className="filter-box">
        <div className="filterTitle">
          <h4>Filters:</h4>
        </div>
        {rows.map((row, index) => (
          <div className="filter-selects" key={row.id}>
            <div className="filter-row">
              <div className="filter-field-select">
                <SelectBox 
                  selectValue={(value) => handleValue(row.id, 'field', value)}
                  selectObject={{ label: 'Select Field', menuItems: fieldSelect, smallSize: true }} 
                  initialValue={row.field}
                />
              </div>
              <div className="operator-field-select">
                <SelectBox 
                  selectValue={(value) => handleValue(row.id, 'operator', value)}
                  selectObject={{ label: 'Select Operator', menuItems: operatorSelect[row.id] || [], smallSize: true }} 
                  initialValue={row.operator}
                />
              </div>
              <div className="value-text-select">
                <TextField size="small" label="Value" 
                value={row.value}
                onChange={(event) => handleValue(row.id, 'value', event.target.value)}/>
              </div>
              <div className="minus-filter-select">
                <Button onClick={() => removeRow(row.id)}>
                  <RemoveCircleIcon/>
                </Button>
              </div>
            </div>
          </div>
        ))}
        <div className="filter-row">
          <div className="left-align">
            <div className="submit-filter">
              <Button 
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Submit'} 
              </Button>
            </div>
          </div>
          <div className="right-align">
            <div className="add-filter-button">
              <Button 
                variant="outlined"
                onClick={addRow}>
                  <AddCircleOutlinedIcon sx={{ mr: 1 }}/>
                Add Filter 
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterPanel