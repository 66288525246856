import Axios from 'axios';
import { config } from '../../config/Constants';

export const getReport = async (reportName, companyName) => {
  try {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: reportName,
        companyName: companyName
      }
    });
     return response.data.report; 
  } catch (error) {
    console.log('Error fetching report', error);
    return [];
  }
}

export const getFilteredReport = async (reportName, companyName, filterParams) => {
  try {
    const response = await Axios.get(config.url.GET_FILTERED_REPORT, {
      params: {
        reportName: reportName,
        companyName: companyName,
        filterParams: JSON.stringify(filterParams)
      }
    });
    return response.data.report;
  } catch(error) {
    return [];
  }
}