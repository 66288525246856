import devConfig from './config-dev';
import prodConfig from './config-prod';
import testConfig from './config-test';

let config;
const host = window.location.host;
console.log(host);

if (host.includes('localhost')) {
    config = devConfig;
} else if (host.includes('test')) {
    config = testConfig;
} else if (host.includes('customerportal')) {
    config = prodConfig;
}  else {
    config = prodConfig;
}

export { config };